<script>
import {mapGetters} from "vuex";
import TransitionToggle from "../../transitions/toggle.vue";
import _ from "lodash";
import PositionLine from "../position-line.vue";

export default {
    name: "skill-selector",

    components: {PositionLine, TransitionToggle},

    emits: ['add', 'select'],

    props: {
        excludes: {
            type: Array,
            default: () => { return []; }
        }
    },

    computed: {
        ...mapGetters({
            skillLevels: 'skillLevel/all',
            skills: 'skill/searchList',
            types: 'skill/types',
            skillLevelLoading: 'skillLevel/loading',
            skillLoading: 'skill/loadingSearch',
            noMore: 'skill/searchEnd'
        }),

        skillsByType: function () {
            const ret = {};
            this.types.forEach(type => {
                ret[type.id] = [];
            })
            this.skills.forEach(skill => {
                if (ret[skill.skill_type_id]) {
                    ret[skill.skill_type_id].push(skill);
                }
            })
            return ret;
        },

        loading: function () {
            return this.skillLevelLoading || this.skillLoading;
        },

        empty: function () {
            return !this.selectedSkills.length;
        },
    },

    data: function () {
        return {
            selectedSkills: [],
            temp: '',
            search: '',
            prev: '',
        };
    },

    methods: {
        fetchSkillLevels: function () {
            this.$store.dispatch('skillLevel/all')
        },

        fetchSkills: function () {
            this.$store.dispatch('skill/all', {with: ['skillType']})
        },

        init: function () {
            if (!this.users?.length) {
                this.fetch(null)
            }
        },

        fetch: function (search) {
            this.$store.dispatch('skill/search', {search: search, per_page: this.limit}).then(() => {
                this.prev = search;
            });
        },

        more: function () {
            this.$store.dispatch('skill/moreSearch')
        },

        clear: function () {
            this.selectedSkills = [];
            this.$store.dispatch('skill/clearSearch');
        },

        select: function (item = {}) {
            if (item.id && !this.selectedSkills.find(s => s.id === item.id)) {
                this.selectedSkills.push(Object.assign({}, item));
            }
        },

        remove: function (index) {
            this.selectedSkills.splice(index, 1);
        },

        removeSkillLevel: function (index) {
            delete this.selectedSkills[index]?.level;
        },

        selectSkillLevel: function (id, item = {}) {
            this.selectedSkills.find(s => s.id === id).level = item;
        },

        isSelected: function (id) {
            return this.selected.findIndex(s => s.id === id) > -1 || this.excludes.includes(id);
        },

        emitSelect: function () {
            this.$emit('select', {skills: this.selectedSkills});
            this.clear();
        },
    },

    watch: {
        search: {
            handler: function (value) {
                this.temp = value;
                _.debounce( (value) => {
                    if (this.temp === value) {
                        this.search = value;
                        this.fetch(value);
                    }
                }, 700)(value);
            },
        }
    },

    created() {
        this.fetchSkills()
        this.fetchSkillLevels()
    }
}
</script>

<template>
    <div class="skill-selector bg-white pt-4 p-3 rounded">
        <div class="row mb-1">
            <div class="col-12 mb-3">
                <div class="row">
                    <div class="col-lg-3">
                        <b-button :disabled="empty"  :title="$t('base.add').ucFirst()" class="w-100 text-center" variant="outline-light" @click="emitSelect">
                            <i class="mdi mdi-chevron-left"></i>
                        </b-button>
                    </div>
                    <div class="col-lg-9">
                        <span class="d-inline-block mb-1" v-for="(selectedSkill, index) in selectedSkills" :key="selectedSkill.id">
                            <span v-if="selectedSkill?.id" class="badge d-flex align-items-stretch p-0 me-2 cursor-pointer" :style="{backgroundColor: selectedSkill?.color}" @click="remove(index)">
                                <span class="d-inline-block p-1">
                                    <i v-if="selectedSkill?.icon" :class="selectedSkill.icon" class="mx-1"></i>
                                    <span>{{ selectedSkill.translation_key ? $t(selectedSkill.translation_key).ucFirst() : selectedSkill.name }}</span>
                                </span>
                                <span v-if="selectedSkill.level?.id" class="d-flex cursor-pointer align-items-center rounded-right ps-1 pe-2" :style="{backgroundColor: selectedSkill.level?.color}" @click.stop="removeSkillLevel(index)">
                                    <i v-if="selectedSkill.level?.icon" :class="selectedSkill.level.icon" class="me-1"></i>
                                    <span>{{ selectedSkill.level?.translation_key ? $t(selectedSkill.level.translation_key).ucFirst() : selectedSkill.level?.name }}</span>
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="search-box bg-white">
                    <div class="position-relative">
                        <input v-model="search" @focus="init" type="text" class="form-control bg-light border-0" :placeholder="$t('base.search').ucFirst() + ' ...'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" class="eva eva-search-outline search-icon"><g data-name="Layer 2"><g data-name="search"><rect width="24" height="24" opacity="0"></rect><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path></g></g></svg>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <transition-toggle>
                    <div v-if="skills?.length" class="max-h-32 overflow-y-scroll">
                        <div class="mb-3" v-for="(type) in types" :key="type.id">
                            <h6 class="text-muted">{{ type.translation_key ? $t(type.translation_key).ucFirst() : type.name }}</h6>
                            <template v-for="(skill, index) in skillsByType[type.id]" :key="index">
                                <position-line
                                    class="cursor-pointer mb-1"
                                    :class="{'bg-light': selectedSkills?.findIndex(selected => selected.id === skill.id) > -1}"
                                    :position="skill" @click="select(skill)"
                                ></position-line>
                                <transition-toggle>
                                    <div v-if="selectedSkills?.findIndex(selected => selected.id === skill.id) > -1">
                                        <position-line v-for="(level) in skillLevels"
                                                       :key="level.id"
                                                       :position="level"
                                                       class="ms-4 cursor-pointer mb-1"
                                                       :class="{'bg-light': selectedSkills?.find(selected => selected.id === skill.id)?.level?.id === level.id}"
                                                       @click="selectSkillLevel(skill?.id, level)"
                                        >
                                        </position-line>
                                    </div>
                                </transition-toggle>
                            </template>
                        </div>
                    </div>
                </transition-toggle>
                <div class="">
                    <transition-toggle>
                        <b-button variant="outline-light" class="w-100 mt-2" v-if="!noMore && skills?.length" @click="more" :disabled="loading">
                            <b-spinner small v-if="loading"></b-spinner>
                            <span v-else>{{ $t('base.more').ucFirst() }}</span>
                        </b-button>
                    </transition-toggle>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .rounded-right {
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
    }
</style>
