<script>
import {mapGetters} from "vuex";
import SkillSelector from "./skill-selector.vue";
import SkillCard from "../skill-card.vue";
import Swal from "sweetalert2";

export default {
    name: "skill-manager",

    components: {SkillCard, SkillSelector},

    props: {
        userId: {
            type: [String, Number]
        }
    },

    computed: {
        ...mapGetters({
            loading: 'user/loadingSkills',
            userSkills: 'user/skills',
            pivots: 'user/skillPivots'
        }),

        excludes: function () {
            return this.userSkills?.map(m => m.id) || [];
        },
    },

    methods: {
        sync: function (pivots) {
            this.$store.dispatch('user/syncSkills', {id: this.userId, pivots: pivots})
        },

        add: function ({skills}) {
            const pivots = {};

            skills?.forEach(skill => {
                const id = skill?.id;

                pivots[id] = {
                    user_id: this.userId,
                    skill_level_id: skill.level?.id
                }
            });

            this.sync(Object.assign({}, this.pivots, pivots))
        },

        remove: function (id, skill) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    const pivots = Object.assign({}, this.pivots);

                    delete pivots[id];

                    console.log(pivots, id, skill);

                    this.sync(pivots);
                }
            });
        },

        fetch: function () {
            this.$store.dispatch('user/skills', this.userId);
        },
    },

    watch: {
        userId: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.fetch();
                }
            },
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{ $tc('organizations::skills.skill', 2).ucFirst() }}</h5>
        </div>
        <div class="card-body bg-light rounded-bottom">
            <div class="row">
                <div class="col-lg-6">
                    <b-overlay :show="loading">
                        <skill-card v-for="(skill) in userSkills" :key="skill.id" :skill="skill" @remove="remove(skill.id, skill)"></skill-card>
                    </b-overlay>
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body p-1">
                            <skill-selector :excludes="excludes" @select="add"></skill-selector>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
