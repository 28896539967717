<script>
export default {
    name: "position-card",

    props: {
        position: {
            type: Object,
            required: true
        },

        ceo: {
            type: Boolean,
            default: false
        },

        clickDescription: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            showModal: false,
            title: null,
            content: null
        }
    },

    methods: {
        readDescription: function (position, full = false) {
            if (full !== this.clickDescription) {
                return;
            }
            this.title = position?.translation_key ? this.$t(position.translation_key).ucFirst() : position?.name;
            this.content = position.description;
            this.showModal = true;
        },
    }
}
</script>

<template>
    <div class="card" :class="{'cursor-pointer': clickDescription}" @click="readDescription(position, true)">
        <div class="card-body rounded-top bg-gradient py-3" :class="{'rounded-bottom': !position?.hierarchy_level, 'pb-2': ceo}">
            <div class="d-flex align-items-center w-100" :class="{'flex-column': ceo}">
                <div :class="{'me-3': !ceo, 'mb-1': ceo}">
                    <div v-if="position?.icon" class="font-size-24" :class="{'me-2': !ceo, 'bg-primary': !position?.color}" :style="{color: position?.color}">
                        <i :class="position.icon"></i>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="position d-flex justify-content-between w-100">
                        <h5 class="text" :class="{'mb-0': !(position?.specialization || position?.knowledge_level), 'font-size-16': ceo}">
                            <span>{{ position?.translation_key ? $t(position.translation_key).ucFirst() : position?.name }}</span>
                        </h5>
                        <div v-if="position?.description && !clickDescription" class="description ms-3 cursor-pointer">
                            <i class="bx bx-detail text-info" @click.stop="readDescription(position)"></i>
                        </div>
                    </div>
                    <div v-if="position?.specialization || position?.knowledge_level" class="spec-and-level">
                        <div class="d-inline-block spec">
                            <i v-if="position?.specialization?.icon" class="me-2" :class="position.specialization.icon" :style="{color: position?.specialization?.color}"></i>
                            <span>{{ position?.specialization?.translation_key ? $t(position.specialization.translation_key).ucFirst() : position?.specialization?.name }}</span>
                        </div>
                        <span class="mx-2">|</span>
                        <div class="d-inline-block  level">
                            <i v-if="position?.knowledge_level?.icon" class="me-2" :class="position.knowledge_level.icon" :style="{color: position?.knowledge_level?.color}"></i>
                            <span>{{ position?.knowledge_level?.translation_key ? $t(position.knowledge_level.translation_key).ucFirst() : position?.knowledge_level?.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal v-model="showModal" :title="title" hide-footer>
                {{ content }}
            </b-modal>
        </div>
        <div v-if="ceo" class="card-body p-0">
            <div class="bg-light text-muted text-uppercase font-size-10">
                {{ $t('organizations::positions.ceo') }}
            </div>
        </div>
        <div v-if="position?.hierarchy_level" class="card-footer py-0 bg-light rounded-bottom text-center">
            <small>
                <i class="me-2" :class="position.hierarchy_level.icon" :style="{color: position.hierarchy_level.color}"></i>
                <span>{{ position.hierarchy_level.translation_key ? $t(position.hierarchy_level.translation_key).ucFirst() : position.hierarchy_level.name }}</span>
            </small>
        </div>
    </div>
</template>

<style scoped>

</style>
